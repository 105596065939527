@use 'variables';
@use 'mixins';

:root.default-dark {
  @include mixins.spread-map(variables.$theme-map-default-dark);
}

:root.au {
  @include mixins.spread-map(variables.$theme-map-au);
}

:root.holland {
  @include mixins.spread-map(variables.$theme-map-holland);
}

:root.de-ca {
  @include mixins.spread-map(variables.$theme-map-de-ca);
}

:root.br-mx-zaf {
  @include mixins.spread-map(variables.$theme-map-br-mx-zaf);
}

:root.india {
  @include mixins.spread-map(variables.$theme-map-india);
}

:root.indonesia {
  @include mixins.spread-map(variables.$theme-map-indonesia);
}

:root.vip {
  @include mixins.spread-map(variables.$theme-map-vip);
}

:root.theme-transition,
:root.theme-transition * {
  transition: background-color variables.$theme-transition-duration;
  transition-delay: 0s;
}
