// colors
$color-background: #07041C;
$color-gradient: linear-gradient(237.12deg, #FFCEA8 0%, #C3A6FC 50.52%, #80FEF7 100%);
$color-gradient-box-shadow: (0 0 5px -2px #FFCEA8, 0 0 7px 0 #C3A6FC, 0 0 24px 3px #80FEF7);
$color-gradient-text: #C3A6FC;
$color-success: #00BB83;
$color-error: #FD387F;
$color-pending: #C3A6FC;
$color-primary: #fff;
$color-secondary: #58607D;
$color-tertiary: #241F43;
$color-fourth: #3C375B;
$color-background-gradient: linear-gradient(180deg, #14102B 0%, #282349 100%);
$color-crypto-payments: invert(53%) sepia(21%) saturate(719%) hue-rotate(208deg) brightness(87%) contrast(85%);
$color-input-active: url(/assets/img/theme/default/input-gradient.svg);
$theme-features-icon: url(/assets/img/theme/default/features/theme-features-icon.png);
$theme-quotes-icon: url(/assets/img/blog/icons/theme/default-quotes.svg);
$theme-wins-icon: url(/assets/img/theme/default/wins.png);

// vars
$color-background-var: --color-background;
$color-gradient-var: --color-gradient;
$color-gradient-box-shadow-var: --color-gradient-box-shadow;
$color-gradient-text-var: --color-gradient-text;
$color-success-var: --color-success;
$color-error-var: --color-error;
$color-pending-var: --color-pending;
$color-primary-var: --color-primary;
$color-secondary-var: --color-secondary;
$color-tertiary-var: --color-tertiary;
$color-fourth-var: --color-fourth;
$color-background-gradient-var: --color-background-gradient;
$color-input-active-var: --color-input-active;

// generated by https://responsive-css.spritegen.com/
$theme-features-icon-var: --theme-features-icon;
$theme-quotes-icon-var: --theme-quotes-icon;
$theme-wins-icon-var: --theme-wins-icon;

$theme-map-default-dark: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(237.12deg, #FFCEA8 0%, #C3A6FC 50.52%, #80FEF7 100%),
  $color-gradient-box-shadow-var: (0 0 5px -2px #FFCEA8, 0 0 9px -1px #C3A6FC, 0 0 16px 4px #80FEF7),
  $color-gradient-text-var: #C3A6FC,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/default/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/default/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/default-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/default/wins.png),
);

$theme-map-au: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(225.18deg, #B0FFB7 5.2%, #4FE7B7 49.48%, #02BFC1 95.65%),
  $color-gradient-box-shadow-var: (0 0 5px -3px #B0FFB7, 0 0 7px 3px #4FE7B7, 0 0 24px 3px #02BFC1),
  $color-gradient-text-var: #33D9A4,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/au/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/au/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/au-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/au/wins.png),
);

$theme-map-holland: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(222.83deg, #FFDD66 3.51%, #FA9B66 50.25%, #FA9B66 57.28%, #FF7C33 100%),
  $color-gradient-box-shadow-var: (0 0 5px -4px #FFDD66, 0 0 7px 0 #FA9B66, 0 0 10px 0 #FA9B66, 0 0 24px 3px #FF7C33),
  $color-gradient-text-var: #FF9559,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/nl/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/nl/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/nl-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/nl/wins.png),
);

// Germany, Canada
$theme-map-de-ca: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(222.83deg, #FFFFFF 3.51%, #FFFFFF 3.52%, #F43838 99.99%),
  $color-gradient-box-shadow-var: (0 0 5px 0px #FFFFFF, 0px 0 19px 8px #F43838),
  $color-gradient-text-var: #FF505A,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/de-ca/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/de-ca/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/de-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/de-ca/wins.png),
);

// Brazil, Mexico, Africa
$theme-map-br-mx-zaf: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(225deg, #DCF929 0%, #88E73D 52.6%, #4DDD4A 100%),
  $color-gradient-box-shadow-var: (0 0 5px -4px #DCF929, 0 0 15px 0px #88E73D, 0 0 30px 3px #4DDD4A),
  $color-gradient-text-var: #7DEC26,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/br-mx-af/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/br-mx-af/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/af-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/br-mx-af/wins.png),
);

$theme-map-india: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(225deg, #FFCD92 0%, #FB8398 51.04%, #BC9CFF 100%),
  $color-gradient-box-shadow-var: (0 0 5px -4px #FFCD92, 0 0 12px 0 #FB8398, 0 0 24px 4px #BC9CFF),
  $color-gradient-text-var: #EF7CA7,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/in/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/in/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/india-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/in/wins.png),
);

$theme-map-indonesia: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(225deg, #FFDC97 0%, #FF9A84 52.08%, #FA88A4 100%),
  $color-gradient-box-shadow-var: (0 0 8px -3px #FFDC97, 0 0 11px -3px #FF9A84, 0 0 24px 4px #FA88A4),
  $color-gradient-text-var: #FF9877,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/id/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/id/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/indonesia-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/id/wins.png),
);

$theme-map-vip: (
  $color-background-var: $color-background,
  $color-gradient-var: linear-gradient(225deg, #FFEA7A 0%, #F7B971 50.52%, #E1954F 100%),
  $color-gradient-box-shadow-var: (0 0 5px -4px #FFEA7A, 0 0 7px 0 #F7B97, 0 0 px -5px #E1954F),
  $color-gradient-text-var: #DDAC63,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-pending-var: #C3A6FC,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-background-gradient-var: linear-gradient(180deg, #14102B 0%, #282349 100%),
  $color-input-active-var: url(/assets/img/theme/vip/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/vip/features/theme-features-icon.png),
  $theme-quotes-icon-var: url(/assets/img/blog/icons/theme/vip-quotes.svg),
  $theme-wins-icon-var: url(/assets/img/theme/vip/wins.png),
);

// fonts
$font-default: "Kanit";

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;
$theme-transition-duration: 1500ms;

// breakpoints
$bp-xs-ios: 340px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;
