@use "sass:math";
@use "sass:map";
@use "variables";

@mixin font($size, $weight: null, $family: null) {
  font-size: calc($size / variables.$font-size-default * 1rem);
  @if $weight != null {
    font-weight: $weight
  }
  @if $family != null {
    font-family: $family
  }
}

@mixin text-stroke($color, $width) {
  -webkit-text-stroke: $width $color;
  text-stroke: $width $color;
  -webkit-fill-color: transparent;
  fill-color: transparent;
}

@mixin ngError() {
  &.ng-invalid.ng-touched {
    @content;
  }
}

@mixin scrollbar($colorScrollbar: theme-var(variables.$color-background-var), $colorThumb: variables.$color-secondary) {
  -webkit-overflow-scrolling: touch;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: $colorThumb $colorScrollbar;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background-color: $colorScrollbar;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 8px;
    background-color: $colorThumb;
  }
}

@mixin hideScrollBar() {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox old
  scrollbar-width: none; /* Firefox new */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin media-between($lower, $upper) {
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin media-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-height-between($lower, $upper) {
  @media (min-height: $lower) and (max-height: ($upper - 1)) {
    @content;
  }
}

@mixin media-height-below($breakpoint) {
  @media (max-height: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-height-above($breakpoint) {
  @media (min-height: $breakpoint) {
    @content;
  }
}

@mixin media-below-and-landscape($breakpoint) {
  @media (max-width: ($breakpoint - 1)) and (min-aspect-ratio: 13/9) {
    @content;
  }
}

@mixin flex($direction: row, $justify: flex-start, $align-items: flex-start) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align-items;
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

/*
  Returns a CSS variable property specific to themeing.
  Accepts an optional fallback, and optional map.
  - SCSS: color: mixins.theme-var($--theme-primary, blue)
  - CSS:  color: var(--theme-primary, blue)
  Throws error if the variable not part of the map
*/
@function theme-var($key, $fallback: null, $map: variables.$theme-map-default-dark) {
  @if not map.has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}

@mixin default-mode($context-selector: null) {
  :host-context(html.default-dark) {
    @content;
  }
}

@mixin country-mode-nl($context-selector: null) {
  :host-context(html.holland) {
    @content;
  }
}

@mixin country-mode-au($context-selector: null) {
  :host-context(html.au) {
    @content;
  }
}

@mixin country-mode-de-ca($context-selector: null) {
  :host-context(html.de-ca) {
    @content;
  }
}

@mixin country-mode-br-mx-zaf($context-selector: null) {
  :host-context(html.br-mx-zaf) {
    @content;
  }
}

@mixin country-mode-india($context-selector: null) {
  :host-context(html.india) {
    @content;
  }
}

@mixin country-mode-indonesia($context-selector: null) {
  :host-context(html.indonesia) {
    @content;
  }
}

@mixin country-mode-vip($context-selector: null) {
  :host-context(html.vip) {
    @content;
  }
}

@mixin textFill() {
  background: theme-var(variables.$color-gradient-var);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  position: relative;
  z-index: 0;
  transition: .3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    background-origin: border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}

@mixin gradientColor() {
  background: theme-var(variables.$color-gradient-var);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradientCard() {
  --r: 8px; /* radius */
  --b: 2px; /* border width */
  background: theme-var(variables.$color-gradient-var);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: theme-var(variables.$color-primary-var);
  color: transparent;
  border-radius: var(--r);
  z-index: 0;
  text-decoration: none;
  position: relative;
  transition: all .3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--r);
    border: var(--b) solid transparent;
    background: inherit;
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-composite: xor;
  }

  &:hover {
    -webkit-text-fill-color: theme-var(variables.$color-primary-var);
  }
}

@mixin glitchEffect() {
  color: white;
  position: relative;
  @include font(35px, 800);

  @keyframes noise-anim {
    $steps: 20;
    @for $i from 0 through $steps {
      #{percentage($i*(calc(1 / $steps)))} {
        clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      }
    }
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    left: 1px;
    top: 0;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    width: 100%;
  }
}
